@import "./styles/variables";
@import "./styles/fonts";
@import "./styles/animated";
@import "mixin-element";

@import '@angular/material/prebuilt-themes/indigo-pink.css';

@import 'igniteui-angular/lib/core/styles/themes/index';
@include core();
@include theme($default-palette);


body {
  background-color: white;
  margin          : 0;
  padding         : 0;
  overflow-x      : hidden;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin            : 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

input[type="password"] {
  font-family            : "fontello", sans-serif;
  font-style             : normal;
  font-weight            : normal;
  font-variant           : normal;
  text-transform         : none;
  -webkit-font-smoothing : antialiased;
  font-size              : 8px;
  -moz-osx-font-smoothing: grayscale;
}

/* chrome elimina backround de los input al llenar con sugerencia */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  transition: 1000s ease-in-out 0s;
}


// .mat-snack-bar-container {
//   padding      : 0px !important;
//   border-radius: 10px !important;
// }


.mat-snack-bar-container {
  padding            : 0px !important;
  border-radius      : 10px !important;
  // max-width       : 369px !important;
  margin             : 25px 19px 0px 17px !important;
  box-shadow         : 0 16px 24px 0 rgba(33, 33, 33, 0.14) !important;
  background-color   : transparent !important;
  min-width          : initial !important;
  min-height         : initial !important;
  max-width          : initial !important;

  @include for-tablet-portrait-up {
    margin: 25px 25px 0px 25px !important;
  }

  @include for-desktop-up {
    margin: 25px 25px 0px 25px !important;
  }
}

.fp-scrollbar {
  --scrollbar-color            : var(--color-secundary-bluedark-solid-bd-50) !important;
  --scrollbar-thumb-color      : #addc71 !important;
  --scrollbar-thumb-hover-color: #93bd5d !important;
  --scrollbar-size             : 2.5px !important;
  --scrollbar-track-color      : #ecedee !important;
}

.no-overflow {
  overflow: hidden;
}

.loader-active {
  height  : 100vh;
  overflow: hidden;
}

.mat-select-panel {
  box-shadow: 0 1px 2px 0 rgba(33, 33, 33, 0.14) !important;
}

.fp-address-select {
  font-family   : SofiaProLight, sans-serif !important;
  font-size     : 14px !important;
  font-stretch  : normal !important;
  font-style    : normal !important;
  line-height   : 1.71 !important;
  letter-spacing: 0.22px !important;
  color         : var(--color-neutral-carbon-solid-c-mid-6-2) !important;
  z-index       : 1002 !important;

  .mat-option-text {
    color: var(--color-neutral-carbon-solid-c-mid-6-2) !important;
  }
}

.fp-snack-modify {
  @include for-tablet-portrait-up {
    margin-left: 12rem !important;
  }

  @include for-desktop-up {
    margin-left: 13.5rem !important;
  }
}

.grecaptcha-badge {
  visibility: hidden;
}